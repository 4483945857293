// import {
//   staticDomainURL
// } from '@/config'
import {
  downFile
} from '@/api/manage.js'
// import Vue from 'vue'

/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar, subStr) {
  if (!avatar) {
    // 没有图片就显示默认图片
    return require('@/assets/images/default.jpg')
  }
  if (!subStr) subStr = 'http'
  try {
    if (avatar && avatar.startsWith(subStr)) {
      return avatar;
    } else {
      if (avatar && avatar.length > 0 && avatar.indexOf('[') == -1) {
        // return staticDomainURL + "/" + avatar;
        return 'http://pc.qianshicloud.com/api/sys/common/static' + "/" + avatar;
      }
    }
  } catch (err) {
    return;
  }
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadFile(url, id, fileName, callBack) {
  var parameter = {
    datumId: id
  }
  return downFile(url, parameter).then((data) => {
    if (!data) {
      this.$message.warning("文件下载失败")
      return
    }
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(new Blob([data], {
        type: 'application/pdf'
      }), fileName + '.pdf')
    } else {
      let url = window.URL.createObjectURL(new Blob([data], {
        type: 'application/pdf'
      }))
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName + '.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link); //下载完成移除元素
      window.URL.revokeObjectURL(url); //释放掉blob对象
    }
  }).finally(() => {
    if (callBack) callBack()
  })
}

/**
 * 下载文件
 * @param url 文件路径
 * @param fileName 文件名
 * @param parameter
 * @returns {*}
 */
export function downloadToolsFile(url, id, fileName, callBack) {
  var parameter = {
    toolId: id
  }
  return downFile(url, parameter).then((data) => {
    const _res = data;
    let blob = new Blob([_res], {type: 'application/pdf'});
    let downloadElement = document.createElement("a");
    let href = window.URL.createObjectURL(blob); //创建下载的链接
    downloadElement.href = href;
    // downloadElement.download = fileName; //下载后文件名
    downloadElement.download = fileName + '.pdf'; //下载后文件名
    document.body.appendChild(downloadElement);
    downloadElement.click(); //点击下载
    document.body.removeChild(downloadElement); //下载完成移除元素
    window.URL.revokeObjectURL(href); //释放掉blob对象
    // if (!data) {
    //   this.$message.warning("文件下载失败")
    //   return
    // }
    // if (typeof window.navigator.msSaveBlob !== 'undefined') {
    //   window.navigator.msSaveBlob(new Blob([data], {
    //     type: 'application/pdf'
    //   }), fileName + '.pdf')
    // } else {
    //   let url = window.URL.createObjectURL(new Blob([data], {
    //     type: 'application/pdf'
    //   }))
    //   let link = document.createElement('a')
    //   link.style.display = 'none'
    //   link.href = url
    //   link.setAttribute('download', fileName + '.pdf')
    //   document.body.appendChild(link)
    //   link.click()
    //   document.body.removeChild(link); //下载完成移除元素
    //   window.URL.revokeObjectURL(url); //释放掉blob对象
    // }
  }).finally(() => {
    if (callBack) callBack()
  })
}
