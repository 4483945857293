import axios from 'axios'
import Vue from 'vue'
import { notification } from 'ant-design-vue'
import { ACCESS_TOKEN } from "@/store/mutation-types"
import { apiBaseUrl } from '@/config'
import store from '@/store'

const server = axios.create({
  baseURL: apiBaseUrl,
  timeout: 120000 // 请求超时时间
})

server.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (token) {
    config.headers['X-Access-Token'] = token
  }
  return config
}, error => {
  return Promise.reject(error)
})

server.interceptors.response.use((response) => {
  return response.data
}, error => {
  if (error.response) {
    let data = error.response.data
    const token = Vue.ls.get(ACCESS_TOKEN)
    console.log("------异常响应------", token)
    console.log("------异常响应------", error.response.status)
    switch (error.response.status) {
      case 500:
        console.log("------error.response------", error.response)
        // eslint-disable-next-line no-empty
        var type = error.response.request.responseType;
        if (type === 'blob') {
          blobToJson(data);
          break;
        }
        if (token && data.message.includes("Token失效")) {
          /*Vue.ls.remove(ACCESS_TOKEN)
          window.location.reload()*/
          notification.error({
            message: '温馨提示',
            description: '登录信息已过期,请重新登录!'
          });
          console.log(store);
          store.dispatch('user/Logout').then(() => {
            Vue.ls.remove(ACCESS_TOKEN)
            try {
              let path = window.document.location.pathname
              console.log("location pathname -> " + path)
              if (path != "/" && path.indexOf('/user/login') == -1) {
                window.location.reload()
              }
            } catch (e) {
              window.location.reload()
            }
          })
        } else {
          notification.error({
            message: '系统提示',
            description: data.message,
            duration: 4
          })
        }
        break
      default:
        notification.error({
          message: '系统提示',
          description: data.message,
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
})

/**
 * Blob解析
 * @param data
 */
function blobToJson(data) {
  let fileReader = new FileReader();
  let token = Vue.ls.get(ACCESS_TOKEN);
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result); // 说明是普通对象数据，后台转换失败
      console.log("jsonData", jsonData)
      if (jsonData.status === 500) {
        console.log("token----------》", token)
        if (token && jsonData.message.includes("Token失效")) {
          notification.error({
            message: '温馨提示',
            description: '登录信息已过期,请重新登录!'
          });
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log("blob解析fileReader返回err", err)
    }
  };
  fileReader.readAsText(data)
}

export default server
